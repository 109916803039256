import { useSearchParams } from '@remix-run/react'
import { cn } from '@repo/ui/utils/utils'
import { type PropsWithChildren, createContext } from 'react'
import type { useDataTable } from '~/hooks/useDataTable'

export type DataTableProps = ReturnType<typeof useDataTable>

export const DataTableContext = createContext<DataTableProps | undefined>(
  undefined
)

const DataTable = ({
  children,
  className,
  ...props
}: PropsWithChildren<DataTableProps> & { className?: string }) => {
  return (
    <DataTableContext.Provider value={props}>
      <div className={cn(props.state.isLoading && 'opacity-50', className)}>
        {children}
      </div>
    </DataTableContext.Provider>
  )
}

const DataTableFilter = ({
  columnId,
  children
}: {
  columnId: string
  children: ({
    value,
    onChange,
    onClear
  }: {
    value: string | null
    onChange: (value: string) => void
    onClear: () => void
  }) => React.ReactNode
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentValue = searchParams.get(columnId)

  const handleChange = (value: string) => {
    searchParams.set(columnId, value)
    setSearchParams(searchParams, { replace: true })
  }

  const handleClear = () => {
    searchParams.delete(columnId)
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <div>
      {children({
        value: currentValue,
        onChange: handleChange,
        onClear: handleClear
      })}
    </div>
  )
}

export { DataTable, DataTableFilter }
