import { useLocation, useNavigation, useSearchParams } from '@remix-run/react'
import { useCallback, useMemo } from 'react'
import type { z } from 'zod'
import type { TableParamsSchema } from '~/utils/table'

type SortOrder = z.infer<typeof TableParamsSchema.shape.order>

const paramKeys = {
  sortBy: 'sortBy',
  order: 'order',
  page: 'page',
  pageSize: 'pageSize',
  query: 'query'
} as const

export const useDataTable = ({
  sortBy,
  order,
  page,
  pageSize,
  query,
  pageCount = 0,
  rowCount = 0,
  filters
}: {
  sortBy: string
  order: SortOrder
  page: number
  pageSize: number
  query?: string
  pageCount?: number
  rowCount?: number
  filters?: string[]
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigation = useNavigation()
  const location = useLocation()

  const hasActiveFilter = useMemo(
    () => filters?.some((key) => !!searchParams.get(key)),
    [filters, searchParams]
  )

  const isLoading = useMemo(
    () =>
      navigation.state !== 'idle' &&
      location.pathname === navigation.location.pathname &&
      location.search !== navigation.location.search,
    [navigation, location]
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const goToPage = useCallback((page: number) => {
    searchParams.set(paramKeys.page, String(page))
    setSearchParams(searchParams)
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const setQuery = useCallback((query: string) => {
    searchParams.set(paramKeys.query, query)
    searchParams.set(paramKeys.page, '1')
    setSearchParams(searchParams, { replace: true })
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const setSortBy = useCallback(
    (key: string) => {
      if (sortBy === key) {
        toggleSortOrder()
        return
      }
      searchParams.set(paramKeys.sortBy, key)
      searchParams.set(paramKeys.order, order === 'asc' ? 'desc' : 'asc')
      searchParams.set(paramKeys.page, '1')
      setSearchParams(searchParams, { replace: true })
    },
    [sortBy, order]
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const toggleSortOrder = useCallback(() => {
    searchParams.set(paramKeys.order, order === 'asc' ? 'desc' : 'asc')
    searchParams.set(paramKeys.page, '1')
    setSearchParams(searchParams, { replace: true })
  }, [order])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const updateFilter = useCallback((key: string, value: string) => {
    searchParams.set(key, value)
    searchParams.set(paramKeys.page, '1')
    setSearchParams(searchParams, { replace: true })
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const clearFilter = useCallback((key: string) => {
    searchParams.delete(key)
    searchParams.set(paramKeys.page, '1')
    setSearchParams(searchParams, { replace: true })
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const clearFilters = useCallback(() => {
    if (!filters?.length) return
    for (const filter of filters) {
      searchParams.delete(filter)
    }
    searchParams.set(paramKeys.page, '1')
    setSearchParams(searchParams, { replace: true })
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const toggleFilter = useCallback((key: string, value: string) => {
    const currentValue = searchParams.get(key)
    if (currentValue === value) return clearFilter(key)
    updateFilter(key, value)
  }, [])

  return {
    data: {
      pageCount,
      rowCount
    },
    state: {
      hasActiveFilter,
      isLoading
    },
    params: {
      sortBy,
      order,
      page,
      pageSize,
      query
    },
    control: {
      goToPage,
      setQuery,
      setSortBy,
      toggleSortOrder,
      updateFilter,
      clearFilter,
      clearFilters,
      toggleFilter
    }
  }
}
