import { z } from 'zod'

export const TableParamsSchema = z.object({
  sortBy: z.string(),
  order: z.enum(['asc', 'desc']),
  page: z.coerce.number().min(1).catch(1),
  pageSize: z.coerce.number().min(1),
  query: z.string().trim().optional()
})

export const DataFilterSchema = z.object({
  boolean: z.enum(['true', 'false']).transform((v) => v === 'true'),
  singleSelect: z.string().min(1).nullish(),
  multiSelect: z
    .string()
    .nullish()
    // Empty array will return all records, undefined will trigger default filter
    .transform((v) => {
      if (v === '') return []
      if (v) return v.split(',')
      return undefined
    })
})
