import { Icon } from '@repo/ui/components/Icon.js'
import { TableHead } from '@repo/ui/components/Table.js'
import { Tooltip, TooltipContent, TooltipTrigger } from '@repo/ui/components/Tooltip.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'
import { useTableContext } from './useTableContext'

type DataTableHeadSortProps =
  | {
      sortable: true
      columnId: string
    }
  | {
      sortable?: false
      columnId?: string
    }

type DataTableHeadProps = ComponentProps<typeof TableHead> & {
  align?: 'left' | 'center' | 'right'
  tooltip?: string | React.ReactNode
} & DataTableHeadSortProps

export const DataTableHead = ({
  sortable = false,
  align = 'left',
  tooltip,
  columnId,
  children,
  className,
  ...props
}: DataTableHeadProps) => {
  const { params, control } = useTableContext()

  const sortActive = params.sortBy === columnId

  const TooltipIcon = tooltip ? (
    <Tooltip>
      <TooltipTrigger asChild>
        <Icon name="circle-question" size="sm" />
      </TooltipTrigger>
      <TooltipContent className="text-left">{tooltip}</TooltipContent>
    </Tooltip>
  ) : null

  if (!sortable)
    return (
      <TableHead {...props}>
        <div
          className={cn('flex items-center gap-1', {
            'justify-end': align === 'right',
            'justify-start': align === 'left',
            'justify-center': align === 'center'
          })}
        >
          {children}
          {TooltipIcon}
        </div>
      </TableHead>
    )

  if (!columnId) throw new Error('Sortable columns must have a columnId')

  return (
    <TableHead {...props}>
      <button type="button" onClick={() => control.setSortBy(columnId)} className="w-full">
        <div
          className={cn(
            'flex items-center gap-2',
            {
              'justify-end': align === 'right',
              'justify-center': align === 'center',
              'justify-start': align === 'left'
            },
            className
          )}
        >
          <div className="flex items-center gap-1">
            {children}
            {TooltipIcon}
          </div>
          {sortActive ? (
            <Icon
              name="circle-chevron-up"
              size="sm"
              className={cn(
                'transition-transform duration-300 ',
                params.order === 'desc' ? 'rotate-180' : ''
              )}
            />
          ) : null}
        </div>
      </button>
    </TableHead>
  )
}
